import styled from "styled-components"
import constants from "../constants"
const Divider = styled.hr`
  border: 1px solid ${constants.colours.grey_lightest};
  width: 919px;

  @media only screen and (max-width: 768px) {
    width: 400px;
  }

  @media only screen and (max-width: 414px) {
    width: 350px;
  }
  @media (max-width: 360px) {
    width: 350px;
  }

  @media (max-width: 320px) {
    width: 350px;
  }

  @media (max-width: 375px) {
    width: 300px;
  }

  @media (max-width: 414px) {
    width: 300px;
  }
`

export default Divider
