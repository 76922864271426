import styled from "styled-components"
import constants from "../constants"
const PostContent = styled.div`
  h1,
  h2,
  h3,
  h4 {
    color: ${constants.colours.tripplo_blue};
    font-family: "Roboto", sans-serif;
    font-weight: bold;
  }

  a {
    color: ${constants.colours.tripplo_blue};
  }
  h5 {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
  }

  img {
    width: 60%;
  }

  ul {
    padding: 0px;
  }
  li {
    list-style: none;
  }
`

export default PostContent
