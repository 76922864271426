import styled from "styled-components"

const PostBackgroundPane = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 1000px;
  height: 102%;
  padding: 30px;
  margin-top: -80px;

  @media only screen and (max-width: 768px) {
    width: 600px;
    margin-top: -50px;
    margin-right: 50px;
  }

  @media only screen and (max-width: 414px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 30px;
  }
  @media (max-width: 360px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 5px;
  }

  @media (max-width: 320px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 30px;
  }

  @media (max-width: 375px) {
    width: 350px;
    margin-top: -50px;
    margin-left: 13px;
  }

  // @media (max-width: 1024px) {
  //   width: 550px;
  //   margin-top: -50px;
  //   margin-left: 7%;
  // }
`

export default PostBackgroundPane
