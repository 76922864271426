import styled from "styled-components"
import constants from "../constants"
const DateHeading = styled.p`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 104.6%;
  color: ${constants.colours.grey_light};
`

export default DateHeading
