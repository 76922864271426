import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Row, Col } from "antd"
import { Link } from "gatsby"
import Layout from "../components/layout"
import PostBackgroundPane from "../components/postBackgroundPane"
import HomeHeading from "../components/homeHeading"
import DateHeading from "../components/dateHeading"
import Divider from "../components/divider"
import PostContent from "../components/postContent"
import styled from "styled-components"
import constants from "../constants"
export default function Template({ data }) {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const { edges } = allMarkdownRemark

  let filteredPosts = edges.filter(
    e =>
      e.node.frontmatter.title === "How to add a driver" ||
      e.node.frontmatter.title === "How to add a truck" ||
      e.node.frontmatter.title === "How to add a trailer"
  )

  if (filteredPosts.length === 3) {
    filteredPosts = []
  }

  const sortedPosts = filteredPosts.sort((a, b) =>
    a.node.frontmatter.order > b.node.frontmatter.order ? 1 : -1
  )

  const PostLinks = styled.p`
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;

    color: ${constants.colours.tripplo_blue};
  `

  return (
    <Layout>
      <Row type="flex" justify="center">
        <Col>
          <PostBackgroundPane>
            <Row type="flex" justify="space-between">
              <div>
                <HomeHeading>{frontmatter.title}</HomeHeading>
                <DateHeading>{frontmatter.date}</DateHeading>
              </div>
              <Row type="flex" justify="space-around">
                {sortedPosts.map(s => (
                  <Link to={s.node.frontmatter.path}>
                    <PostLinks style={{ marginRight: "2rem" }}>
                      {s.node.frontmatter.title}
                    </PostLinks>
                  </Link>
                ))}
              </Row>
            </Row>

            <Divider />
            <div>
              <PostContent dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </PostBackgroundPane>
        </Col>
      </Row>
    </Layout>
  )
}
export const pageQuery = graphql`
  query allMarkdownRemarkAndmarkdownRemark($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
    allMarkdownRemark(filter: { frontmatter: { path: { ne: $path } } }) {
      edges {
        node {
          frontmatter {
            path
            title
            order
          }
        }
      }
    }
  }
`
